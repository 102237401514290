module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-0ac7753fdd/3/buildhome/.yarn/berry/cache/gatsby-remark-images-npm-7.13.1-bfe6d0ffe3-10c0.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":888,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-typography-virtual-c9b6151692/3/buildhome/.yarn/berry/cache/gatsby-plugin-typography-npm-5.13.1-7b42ede218-10c0.zip/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/typography.js"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-9b4088300f/3/buildhome/.yarn/berry/cache/gatsby-plugin-catch-links-npm-5.13.1-d0715a6524-10c0.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-785232f6de/3/buildhome/.yarn/berry/cache/gatsby-plugin-google-gtag-npm-5.13.1-993d935611-10c0.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-ZP08KYZV5E"],"gtagConfig":{"anonymize_ip":false},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"delayOnRouteUpdate":0,"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-cb7e87c7d8/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
